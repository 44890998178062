import type  { Directive} from 'vue';

import { appInsights } from 'o365-modules';
import {utils, $t as translate } from 'o365-utils';
import { vTarget } from 'o365-vue-utils';

const vFocus: Directive<HTMLElement, never, 'input', 'delay'> = {
    mounted(el, binding) {
        const focus = () => {
            if (binding.modifiers.input) {
                el.querySelector('input')?.focus();
            } else {
                el.focus();
            }
        };

        if (binding.arg) {
            const timeout = parseInt(binding.arg) || 0;
            window.setTimeout(() => {
                focus();
            }, timeout);
        } else {
            focus();
        }
    }
};

// Adds utility property types for component instances
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $format: typeof utils.format,
        $formatDate: typeof utils.formatDate,
        $formatNumber: typeof utils.formatNumber,
        $formatFileSize: typeof utils.formatFileSize,
        
        /** Function for translating strings */
        $t: typeof translate,
        $track: typeof appInsights.trackEvent,

        /**
         * Directive for autofocusing an element on mount. You can use `v-focus.input` 
         * to query select the first input element and focus that one instead.  
         * To set a delay on the foucs use an int argument like `v-focus:50`
         */
        vFocus: typeof vFocus
    }
}

export default {
    install: (app: any, options: {
        includeProperties: boolean,
        includeDirectives: boolean,
    }) => {
        if (options?.includeProperties ?? true) {
            app.config.globalProperties.$format = utils.format;
            app.config.globalProperties.$formatDate = utils.formatDate;
            app.config.globalProperties.$formatNumber = utils.formatNumber;
            app.config.globalProperties.$formatFileSize = utils.formatFileSize;
            app.config.globalProperties.$track = appInsights.trackEvent;
            app.config.globalProperties.$t = translate;
        }

        if (options?.includeDirectives ?? true) {
            app.directive('target', vTarget);
            app.directive('focus', vFocus);
        }
    }
}
